import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { createContext, useState, useEffect } from 'react';


export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const db = getFirestore();
  // this is the sender id means who will send the messae to reciepient 
  const userID = localStorage.getItem("userId");
const accessId = localStorage.getItem("userId")
  useEffect(() => {
    console.log("0192109", accessId);
    
    const fetchData = async () => {
      try {
        if (userID) { 
          const docRef = doc(db, "users", userID); 
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            console.log('\x1b[1m\x1b[31mUser Data:\x1b[0m' , userData);
            setCurrentUser(userData);
          } else {
            console.error('User document does not exist for specified ID refrence current user !');
          }
        } else {
          console.error('User ID not found in localStorage');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error gracefully
      }
    };
    fetchData();
  }, [userID, db]);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
