import React from "react";

function Walletfrom() {
  return (
    <>
      <div className="flex flex-col items-center fade-in-right">
        <form action="">
          {/* input 1  */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[18rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              VPA address
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            />
          </div>
          {/* input 2  */}
          <h5 className="mt-2 font-all font-semibold">Billing Address</h5>
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[18rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              Country
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            />
          </div>
          {/* 3  */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[18rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[10rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              Street Address Line 1*
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            />
          </div>
          {/* 4  */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[18rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[10rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              Street Address Line 2*
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            />
          </div>
          {/* 5 */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[18rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[47px] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content  "
            >
              City*
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            />
          </div>
          {/* 6 */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[18rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              State/Province*
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            />
          </div>
          {/* 7  */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[18rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              Postal code*
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            />
          </div>
          {/* 8   */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[18rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              Tax status*
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            />
          </div>
          {/* 9 */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[18rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[12rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              Billing and tax information*
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
            />
          </div>

          <button className=" mb-6 bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[1rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
            Save Changes
          </button>
        </form>
      </div>
    </>
  );
}

export default Walletfrom;
