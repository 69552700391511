import React from 'react'
import Createpost from './Createpost'
import CommunityPost from './CommunityPost'
import ProductsliderPost from './ProductsliderPost'
import ServicesPost from './ServicesPost'
import JobsPost from './JobsPost'
import AddCreatePost from '../../AddProject/AddProjectPost/AddCreatePost'

function Postmain() {
  return (
    <>
    <AddCreatePost/>
    <CommunityPost/>
    {/* <ProductsliderPost/> */}
    {/* <ServicesPost/> */}
    {/* <JobsPost /> */}
    </>
  )
}

export default Postmain
