import React from 'react'

export default function UserDetaiLTable() {
  return (
    <>
      

<div className='bg-layout shadow-md drop-shadow-md lg:w-[155vh]  mb-[7rem]  lg:ml-[268px] relative top-[1rem] fade-in-left'>
<h1 className=" font-all  font-extrabold text-start text-[25px] ml-8 mt-8 mb-7 ">Your Dashboard</h1>
<h1 className=" font-all  font-extrabold text-start text-[15px] ml-8 mt-3 mb-7 ">Status <span className='font-all text-green-500' >: (ACTIVE)</span></h1>

<div className="lg:w-2/3 w-full mx-auto overflow-auto">
    <table className="table-auto w-full text-left whitespace-no-wrap mb-10">
      <thead>
        <tr>
          <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-[#E98F92] rounded-tl rounded-bl">
            Username
          </th>
          <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-[#E98F92]">
            Mobile no
          </th>
          <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-[#E98F92]">
            Location
          </th>
          <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-[#E98F92]">
            Total spending
          </th>
          <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-[#E98F92]">
            Type of User
          </th>
        </tr>
      </thead>
      <tbody className='mb-4'>
        <tr>
          <td className="px-4 py-3">***</td>
          <td className="px-4 py-3">***</td>
          <td className="px-4 py-3">***</td>
          <td className="px-4 py-3 text-lg text-gray-900">***</td>
          <td className="px-4 py-3 text-lg text-gray-900">***</td>
          
        </tr>
        <tr>
          <td className="px-4 py-3     bg-[#F0EAEA]">***</td>
          <td className="px-4 py-3  bg-[#F0EAEA]">***</td>
          <td className="px-4 py-3     bg-[#F0EAEA]">***</td>
          <td className="px-4 py-3     bg-[#F0EAEA] text-lg text-gray-900">***</td>
          <td className="px-4 py-3      bg-[#F0EAEA] text-lg text-gray-900">***</td>
          
        </tr>
        <tr>
          <td className="px-4 py-3">***</td>
          <td className="px-4 py-3">***</td>
          <td className="px-4 py-3">***</td>
          <td className="px-4 py-3 text-lg text-gray-900">***</td>
          <td className="px-4 py-3 text-lg text-gray-900">***</td>
          
        </tr>
        <tr>
          <td className="px-4 py-3     bg-[#F0EAEA]">***</td>
          <td className="px-4 py-3  bg-[#F0EAEA]">***</td>
          <td className="px-4 py-3     bg-[#F0EAEA]">***</td>
          <td className="px-4 py-3     bg-[#F0EAEA] text-lg text-gray-900">***</td>
          <td className="px-4 py-3      bg-[#F0EAEA] text-lg text-gray-900">***</td>
          
        </tr>
        
      </tbody>
    </table>
  </div>
</div>

    </>
  )
}
