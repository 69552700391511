import React from "react";
import { useWallet } from "../../../Wallet/Context/WalletContext";

const ProfilePostHistory = ({profiledata}) => {
    const { postlist } = useWallet();
  return (
    <>
    {postlist.slice(0, 2).map((item, index) => (<>
     
        <div className="flex flex-col justify-center" >
                  <div
                    key={index}
                    className="relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 rounded-xl shadow-mdl p-3  w-[80%] mx-auto border border-white bg-white my-2"
                  >
                    <div className="w-full md:w-1/3 bg-white grid place-items-center  ">
                      <img
                        src={item.mediaUrl}
                        alt="tailwind logo"
                        className="rounded-xl h-[10rem] w-[7rem]"
                      />
                    </div>
                    <div className="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
                  
                      <h3
                        className="font-black text-gray-800 md:text-3xl font-all"
                        style={{ fontSize: "21px" }}
                      >
                        {item.postTitle}
                      </h3>
                      <p className="md:text-lg text-gray-500 text-base">
                        {item.details.split(" ").slice(0, 6).join(" ")}
                      </p>
                    </div>
                  </div>
                </div>
      

     </> ))}
   

        
      
    </>
  );
};

export default ProfilePostHistory;
