// api.js
import axios from "axios";
import * as ENDPOINTS from "../URlcollection";

export const product_promotion = async (promotedvalue) => {
  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/createProductPromotion",
      promotedvalue,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};
// 
export const service_promotion = async (promotedvalue) => {
  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/createServicePromotion",
      promotedvalue,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};
// 
export const job_promotion = async (promotedvalue) => {
  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/createJobPromotion",
      promotedvalue,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};
// 
export const post_promotion = async (promotedvalue) => {
  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/createPostPromotion",
      promotedvalue,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};
// 
export const project_promotion = async (promotedvalue) => {
  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/createProjectPromotion",
      promotedvalue,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};

// 
// promotion list 

export const list_promoted = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/admin/getAllPromotions`,
      {
        headers: {
          Token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0YjE0N2M4MzA3NDUyN2Q4ZjBiZjdjMyIsImVtYWlsIjoiYWhtZWRyYXphY2gxMThAZ21haWwuY29tIiwidXNlclR5cGUiOiJBZG1pbiIsImlhdCI6MTcwMTAyNTM4NSwiZXhwIjoxNzMyNTYxMzg1fQ.nmhalTcNbZ6nfuwzlBhyM5-erQr79iQ7NF2WWauFqJo",
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch product categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const user_interest = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/admin/getAllInterests`,
      {
        headers: {
          Token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0YjE0N2M4MzA3NDUyN2Q4ZjBiZjdjMyIsImVtYWlsIjoiYWhtZWRyYXphY2gxMThAZ21haWwuY29tIiwidXNlclR5cGUiOiJBZG1pbiIsImlhdCI6MTcwMTAyNTM4NSwiZXhwIjoxNzMyNTYxMzg1fQ.nmhalTcNbZ6nfuwzlBhyM5-erQr79iQ7NF2WWauFqJo",
        },
      }
    );

    if (response.status === 200) {

      return response.data.result;
    } else {
      throw new Error("Failed to fetch product categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
//  updateable product reponse 
