import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import { IoLogOut } from "react-icons/io5";
import Sidebar from "../component/Sidebar";
import { Link, Outlet, useNavigate } from "react-router-dom";
import MobileSnackBar from "../../src/Widgets/MobileSnackBar";
import SearchBar from "../component/SearchBar";
import GifComponent from "../pages/Auth/GifComponent";
import SearchFilter from "../Widgets/SearchFilter";

function MainLayout() {
  const [isModalOpen, setModalOpen] = useState(false);
  // const [isReseller, setReseller] = useState(false);
  // const navigate = useNavigate();

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  // Move the visibility function below handleButtonClick

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []); 

  const navigate = useNavigate();

  const handle_logout_session = () => {
    navigate("/auth/verification");
    // console.log("removing" , localStorage.removeItem("token"));
    sessionStorage.setItem("isLogout", "true"); 
  };

  return (
    <>
      {isLoading ? (
        <GifComponent />
      ) : (
        <>
          {isModalOpen && (
            <div className="fade-in-top fixed z-10 overflow-y-auto top-0 w-full left-0">
              <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                  <div className="absolute inset-0 bg-black opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                  &#8203;
                </span>
                <div
                  className="inline-block align-center rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[608px] h-full scale-in-center slide-in-elliptic-top-fwd"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <div className="bg-white flex flex-col items-center">
                    <span
                      onClick={toggleModal}
                      className="relative left-[15rem] mt-[15px] text-[29px] cursor-pointer text-black"
                    >
                      <RxCross1 />
                    </span>
                    <div className="img">
                      <IoLogOut className="lg:h-[9rem] w-[auto] text-[#726a6a]" />
                    </div>
                    <div className="text-2xl text-[#000000] text-center font-semibold leading-relaxed mt-3">
                      Are You Sure You Want to logout
                      <br />
                      <span className="text-center">From Your Account</span>
                    </div>
                  </div>
                  <div className="lg:p-[40px] flex flex-col justify-center items-center bg-white mx-auto gap-2">
                    {/* <Link to="/auth/welcome"> */}
                    <button
                      onClick={handle_logout_session}
                      className="text-white rounded-full bg-[#CB2229] font-all delete p-[6px] w-[16rem]"
                    >
                      Yes, I want to Logout
                    </button>
                    {/* </Link> */}
                    <button
                      style={{ border: "1px solid #CB2229" }}
                      onClick={toggleModal}
                      className="text-black font-all rounded-full p-[6px] w-[16rem]"
                    >
                      No, Go Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Sidebar toggleModal={toggleModal} />

          <div className="hidden md:block sm:block lg:block">
            <SearchBar />
          </div>
          {/* <div className="block   md:hiddeb sm:hidden lg:hidden">
            <SearchFilter />
          </div> */}

          <div className="md:hidden lg:hidden block sticky top-0 z-10">
            <MobileSnackBar />

            <div className=" bg-white h-[3.5rem] mb-2">
              <SearchFilter />
            </div>
          </div>

          <Outlet />
        </>
      )}
    </>
  );
}

export default MainLayout;
