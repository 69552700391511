import React from "react";
import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";
import { CiCirclePlus } from "react-icons/ci";
import { Link } from "react-router-dom";

export default function Intrest() {
  return (
    <>
      <div className="xs:min-h-screen">
<div className="hidden xs:block mt-8 mx-auto">
<img
            src={Logo}
            alt=""
            className="lg:w-[134px] xs:h-[49px] bounce-in-top flex justify-center mx-auto  "
          />
</div>


        <div className="flex flex-row justify-start  ">
          <div className=" xs:hidden  item-1 h-screen lg:w-[100vh] bg-[#cb22290f]">
            <div className="flex flex-col justify-center items-center mt-12 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[70px] text-[#738482] mt-9">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                Register now to gain access to your account{" "}
              </p>
            </div>
            <div className="image relative top-[14px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>

            <div className="flex flex-row items-center gap-4 justify-center mt-[7rem]">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>
          <div className="item-2 bg-[#cb22290f] xs:bg-white w-[50%] xs:w-[100%]  flex ">
            <div className="fade-in-right bg-white xs:bg-[#cb22290f] shdaow-md w-[88%] rounded-md  mt-[9rem]  xs:mt-8 xs:mx-auto   h-[552px] drop-c ">
              <div className="flex flex-row justify-start gap-[4rem] lg:ml-[40px] lg:mt-8">
                <div className="item-1 xs:hidden">
                  <div className="bg-black rounded-full p-[39px]">
                    <p className="text-white">.......</p>
                  </div>
                </div>
                <div className="item-2 xs:mx-auto ">
                  <p className="text-right text-[15px] font-all text-[#000000] relative top-[15px]">
                    * Mandatory fields
                  </p>
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[23rem] ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-[71px] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                    >
                      Interest
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="input"
                      className="input px-2 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[11px]"
                    />
                  </div>

                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[23rem] ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-[44px] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                    >
                      UUID
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="input"
                      className="input px-2 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[11px]"
                    />
                  </div>
                  {/*  */}
                  <h6 className="font-all text-left-ml-3 font-bold mt-4 ">
                    Attach supporting Document
                  </h6>

                  <div className="rounded-md border-[1.1px] border-dashed border-[#738482] p-[22px] mt-4 bg-white">
                    <span className="text-[#738482] flex justify-center items-center text-[50px] ">
                      <CiCirclePlus />
                    </span>
                    <p className=" text-[#738482] text-[14px] text-center">Upload front and back images</p>
                    <p className=" text-[#738482] text-[14px] text-center">for ID verification</p>
                  </div>

<p className="text-[#738482] text-[12px] mt-3">Accepted File Types :jpg , jpeg , jpg & png </p>
                  
                  <Link to="/" >
                  <button className=" mt-[77px] flex justify-center items-center mx-auto m-auto bg-[#CB2229] text-white rounded-full font-all p-[6px]  w-[18rem]   transform hover:scale-105 duration-500 ease-in-out ">
                    Proceed
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
