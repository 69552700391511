import React, { useState } from "react";
import {
  edit_user_store,
  fetchStoreDetails,
} from "../../../../api/userRegestration/CollectionForm";
import { toast, ToastContainer } from "react-toastify";

export default function EditStore({
  storeDetails,
  storeId,
  toggleview,
  updateProductDetails,
}) {
  //
  const [formData, setFormData] = useState({
    name: storeDetails.name,
    type: "",
    location: storeDetails.location,
    PhoneNumber: storeDetails.phoneNumber,
    timing: storeDetails.timing,
    EmailID: storeDetails.mail,
    yearsofEstablishment: storeDetails.established,
    awards: [],
    Certificates: [],
  });
  //
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
    console.log("handleInputChange called");
  };
  //
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const update_store_data = {
        storeId: storeId,
        name: formData.name,
        mail: formData.mail,
        // type:"store",
        // "banner":"s",
        location: formData.location,
        // timing:formData.timing
        // "established":"",
        //  "facebook":"",
        // "twitter":"",
        // "instagram":"",
        //  "linkedIn":"",
        // "website":"",
        //  "award":[],
        //"certificates":["certificates"],
        // "catalogue":[{"name":"shoe","description":"new","price":23}]
        // "phoneNumber":""
      };
      const Product = await edit_user_store(update_store_data);
      console.log("post:", Product);

      toast.success("your store information ins updated successfully", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //

      fetchStoreDetails(storeId).then((response) => {
        updateProductDetails(response.result);
        toast.success("you can now view the bussiness card with changes", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="store_form_regestration">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-row lg:gap-[9rem] mt-[3rem] mb-[2rem]">
            {/* 1 */}
            <div className="form-input flex flex-col items-center w-[47%]">
              <h1 className="font-all font-semibold text-center">
                update your store
              </h1>
              {/* 1 name field  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm px-2 text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2  bg- w-fit-content text-center"
                >
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="name"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/* 2  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                >
                  Location
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="location"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.location}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max  rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                >
                  Timing :
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="timing"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.timing}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/*  */}
              {/* 5  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[7rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  placeholder="Write here..."
                  name="PhoneNumber"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.PhoneNumber}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                >
                  Email ID
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="EmailID"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.EmailID}
                  onChange={handleInputChange}
                />
              </div>{" "}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                >
                  Years of Establishment
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="yearsofEstablishment"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.yearsofEstablishment}
                  onChange={handleInputChange}
                />
              </div>{" "}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                >
                  Awards
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="awards"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.awards}
                  onChange={handleInputChange}
                />
              </div>{" "}
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                >
                  Certificates
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="Certificates"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  value={formData.Certificates}
                  onChange={handleInputChange}
                />
              </div>{" "}
              {/*  */}
            </div>

            {/* 2 */}

            <div className="inline-flex flex-col mt-[4rem] lg:gap-[3rem]">
              {/*  */}

              {/* <p className="text-[#738482]">Accepted Types : jpeg,jpg,png</p> */}

              {/*  */}

              {/* buttin action */}

              <div className=" button-action flex flex-col items-center mt-[3.5rem]">
                <button
                  type="submit"
                  // onClick={hanldeSubmit}
                  className="  bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
                >
                  update store data{" "}
                </button>

                {/* <Link to="/"> */}
                <button
                  onClick={toggleview}
                  className=" mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  "
                >
                  Go Back to my store
                </button>
                {/* </Link> */}
              </div>
            </div>
            {/*  */}
          </div>
        </form>
      </div>
    </>
  );
}
