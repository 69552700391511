import React from "react";
import dds from "../pages/home/staticimages/home.png";

import home from "../pages/home/staticimages/addproduct.png";
import Explore from "../pages/home/staticimages/explore.png";
import Product from "../pages/home/staticimages/addproduct.png";
import ervice from "../pages/home/staticimages/listservice.png";
import Post from "../pages/home/staticimages/jobpost.png";
import Member from "../pages/home/staticimages/member.png";
import laptop from "../pages/home/staticimages/laptop.png";
import Promoted_Product_Card from "./Promoted_Product_Card";
import VIdeo_card from "./VIdeo_card";
import { Link, useNavigate } from "react-router-dom";
import ProductForYou from "../pages/home/ServicesByINO/FindProduct/Allmain/ProductForYou";
function Mobile_SLider_card() {
  const navigate = useNavigate();

  const handlefindproduct = () => {
    navigate(`/findproducts`);
  };
  return (
    <>
      <div className="mobile-item hidden xs:block ">
        <div className="carousel carousel-center max-w-md p-[1px] space-x-4  w-[41vh] ite bg-white mb-4 rounded-none ">
          <div
            className="carousel-item relative  p-0 cursor-pointer"
            onClick={handlefindproduct}
          >
            <div className="overflow-hidden">
              <div
                className="card w-[12rem] shadow-none rounded-[10px] h-[3rem] bg-base-100  bg-cover bg-center overflow-hidden "
                style={{ backgroundImage: `url(${dds})` }}
              >
                <h2 className="card-title mx-auto text-[18px] items-center flex justify-center  w-full h-full bg-[#0000006b] text-white font-all font-semibold">
                  Find Product
                </h2>
              </div>
            </div>
          </div>
          {/*  */}
          <div
            className="carousel-item cursor-pointer"
            onClick={() => {
              navigate("/addProject");
            }}
          >
            <div className="overflow-hidden">
              <div 
                className="card w-[12rem] shadow-none rounded-[10px] h-[3rem] bg-base-100  bg-cover bg-center overflow-hidden "
                style={{ backgroundImage: `url(${home})` }}
              >
                <h2 className="card-title mx-auto text-[18px] items-center flex justify-center  w-full h-full bg-[#0000006b] text-white font-all font-semibold">
                  Add Product
                </h2>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="carousel-item cursor-pointer"
            onClick={() => {
              navigate("/exploreService");
            }}>
            <div className="overflow-hidden">
              <div
                className="card w-[12rem] shadow-none rounded-[10px] h-[3rem] bg-base-100  bg-cover bg-center overflow-hidden "
                style={{ backgroundImage: `url(${Explore})` }}
              >
                <h2 className="card-title mx-auto text-[18px] items-center flex justify-center  w-full h-full bg-[#0000006b] text-white font-all font-semibold">
                  Explore Service
                </h2>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="carousel-item cursor-pointer"
             onClick={() => {
              navigate("/exploreService");
            }}
          
          >
            <div className="overflow-hidden">
              <div
                className="card w-[12rem] shadow-none rounded-[10px] h-[3rem] bg-base-100  bg-cover bg-center overflow-hidden "
                style={{ backgroundImage: `url(${Product})` }}
              >
                <h2 className="card-title mx-auto text-[18px] items-center flex justify-center  w-full h-full bg-[#0000006b] text-white font-all font-semibold">
                  List Service
                </h2>
              </div>
            </div>
          </div>
          <div className="carousel-item curs1or-pointer"
            onClick={() => {
              navigate("/findjobs");
            }}>
            <div className="overflow-hidden">
              <div
                className="card w-[12rem] shadow-none rounded-[10px] h-[3rem] bg-base-100  bg-cover bg-center overflow-hidden "
                style={{ backgroundImage: `url(${ervice})` }}
              >
                <h2 className="card-title mx-auto text-[18px] items-center flex justify-center  w-full h-full bg-[#0000006b] text-white font-all font-semibold">
                  Find Job
                </h2>
              </div>
            </div>
          </div>
          <div className="carousel-item cursor-pointer"
          onClick={()=>{
            navigate("/PostJob")
          }}>
            <div className="overflow-hidden"
            >
              <div
                className="card w-[12rem] shadow-none rounded-[10px] h-[3rem] bg-base-100  bg-cover bg-center overflow-hidden "
                style={{ backgroundImage: `url(${Post})` }}
              >
                <h2 className="card-title mx-auto text-[18px] items-center flex justify-center  w-full h-full bg-[#0000006b] text-white font-all font-semibold">
                  Post Job
                </h2>
              </div>
            </div>
          </div>
          <div className="carousel-item cursor-pointer">
            <div className="overflow-hidden">
              <div
                className="card w-[12rem] shadow-none rounded-[10px] h-[3rem] bg-base-100  bg-cover bg-center overflow-hidden "
                style={{ backgroundImage: `url(${Member})` }}
                onClick={()=>{
                  navigate("/PostProject")
                }}
              >
                <h2 className="card-title mx-auto text-[18px] items-center flex justify-center  w-full h-[3rem] bg-[#0000006b] text-white font-all font-semibold">
                  Post Project
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="reseller cursor-pointer" onClick={()=>{
          navigate("/register/resellerForm")
        }}>
          <div className="carousel-item flex justify-center">
            <div className="overflow-hidden">
              <div
                className="card w-[22rem] shadow-none rounded-[10px] h-[3rem] bg-base-100  bg-cover bg-center overflow-hidden "
                style={{ backgroundImage: `url(${Product})` }}
              >
                <h2 className="card-title mx-auto text-[18px] items-center flex justify-center  w-full h-full bg-[#0000006b] text-white font-all font-semibold">
                  Become a Reseller and Earn
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="vendors">
          <div className=" font-all font-semibold text-[16px] text-center my-4">
            <p className="relative top-[6px]">
              Discover nearby vendors , trends and more ...
            </p>
          </div>

          <div className="vendor_09s_card">
            <div
              className="carousel carousel-center max-w-md p-[1px] space-x-4 rounded-none w-[40vh] bg-white mb-4 flex items-center mx-auto
            "
            >
              <div className="carousel-item">
                <div
                  className="card w-[10rem] shadow-none  h-[4rem] bg-base-100  bg-cover bg-center  "
                  style={{
                    backgroundImage: `url("https://images.unsplash.com/photo-1548345680-f5475ea5df84?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,
                  }}
                >
                  <div className="  card-body h-[3rem] bg-[#0000006b] rounded-[13px] text-white dark:text-white font-all font-semibold   flex justify-center">
                    <h2
                      className="card-title whitespace-nowrap mx-auto text-center text-[18px]"
                      style={{ color: "white" }}
                    >
                      Vendors
                    </h2>
                  </div>
                </div>
              </div>
              <div className="carousel-item ml-[14px]">
                <div
                  className="card w-[10rem] shadow-none  h-[4rem] bg-base-100  bg-cover bg-center  "
                  style={{
                    backgroundImage: `url("https://images.unsplash.com/photo-1516321318423-f06f85e504b3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,
                  }}
                >
                  <Link to={"/marketTrend"}>
                  <div   className="  card-body h-[3rem] bg-[#0000009e] rounded-[13px] text-white  dark:text-white  font-all font-semibold flex justify-center  items-center ">
                    <h2
                      className="card-title whitespace relative right-[-1px] whitespace-nowrap text-[18px]"
                      style={{ color: "white" }}
                    >
                      Market trends
                    </h2>
                  </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="register-store 077572_s">
            <div className="carousel-item">
              <Link to={"/register_your_store"}>
              <div className="card w-[100%] mx-auto shadow-none  h-[4rem] bg-base-100      ">
                <div className="  card-body flex justify-center h-[3rem] bg-[#fbbbbd] rounded-[13px] text-black font-all font-semibold   ">
                  <h2 className="card-title whitespace-nowrap text-center mx-auto">
                    Register with us{" "}
                  </h2>
                </div>
                <p className="text-start italic font-all text-gray-600 font-extralight">
                  Add your Service, Company or Store details
                </p>
              </div>
              </Link>
            </div>
          </div>
          <div className="promote_products mt-6 mb-8 scroll-mb-[7rem]">
            <h2 className="font-all text-black font-semibold text-[19px] mt-[3rem]">
              Products for you
            </h2>

            <ProductForYou />
            <br />
            {/* <Promoted_Product_Card /> */}
            <h2 className="font-all text-black font-semibold text-[19px] mt-[3rem]">
              Add name / Description
            </h2>
            <VIdeo_card />
          </div>
        </div>
      </div>
    </>
  );
}

export default Mobile_SLider_card;
