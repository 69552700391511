import React, { useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { BsPencil } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { registerSrore } from "../../../../api/userRegestration/CollectionForm";
import Editcatalougefrom from "./Store/Editcatalougefrom";
import AddofferForm from "./Store/AddofferForm";
import NotificationModel from "../../../../Widgets/NotificationModel";
import Spinner from "../../../../component/Spinner";
import { toast } from "react-toastify";
import PromotionPopup from "../../../../Widgets/PromotionPopup";
import Catalouge_Offer_Form from "../../../../Widgets/ModuleStore/Catalouge_Offer_Form";
export default function StoreRegestrationForm() {
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    location: "",
    PhoneNumber: "",
    timing: "",
    EmailID: "",
    catalogue: [{ name: "", description: "", price: "" }],
    yearsofEstablishment: "",
    awards: [],
    Certificates: [],
  });
  //
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(false);
  const [offermodule, setOfferModule] = useState(false);
  const [catalougemoule, setCatalougeModule] = useState(false);
  const [notificationmodal, setnotificationmodal] = useState(false);
  const [loading, setLoading] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [promotebutton, setPromoteButton] = useState(false);
  const [togglecatalouge, settogglecatalouge] = useState(false);
  //
  const [isImageSelected, setIsImageSelected] = useState(false);

  const toggleform = () => {
    settogglecatalouge(true);
  };

  //
  //
  //
  //
  const toggenotifcation = () => {
    setnotificationmodal(true);
  };
  const dimissnotification = () => {
    console.log("clicked");
    setnotificationmodal(false);

    setShowModal(true);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      convertImageToBase64(file);
      setIsImageSelected(false);
    } else {
      setSelectedImage(null);
      setImageBase64(null);
      setIsImageSelected(false);
    }
  };

  //
  const convertImageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result;
      setImageBase64(base64Data);
    };
    reader.readAsDataURL(file);
  };

  //
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Check if the field should be treated as an array
    if (name === "awards" || name === "Certificates") {
      // Split the value into an array, e.g., assuming values are comma-separated
      const arrayValue = value.split(",").map((item) => item.trim());

      setFormData((prevData) => ({
        ...prevData,
        [name]: arrayValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  //
  const hanldeSubmit = async (e) => {
    console.log(formData);

    console.log("handlinfj dknf");
    e.preventDefault();
    setLoading(true);
    if (!selectedImage) {
      setIsImageSelected(true);
      return; // Prevent form submission if image is not selected
    }
    try {
      const StoreData = {
        name: formData.name,
        mail: formData.EmailID,
        type: formData.type,
        banner: imageBase64,
        location: formData.location,
        timing: formData.timing,
        established: formData.yearsofEstablishment,
        awards: formData.awards,
        certificates: formData.Certificates,
        catalogue: [{
          name: formData.catalogue[0].name || "",
          description: formData.catalogue[0].description || "",
          price: formData.catalogue[0].price || ""
        }],
        phoneNumber: formData.PhoneNumber,
      };

      const Storeinfo = await registerSrore(StoreData);
      setnotificationmodal(true);
      console.log("registerd_store", Storeinfo);
    } catch (error) {
      console.error("src:component", "Error registering store:", error.message);
    } finally {
      setLoading(false);
    }
  };
  //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  const handleCatalogueChange = (event, index, field) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      catalogue: prevData.catalogue.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      })
    }));
  };
  
  return (
    <>
      <div className="  bg-layout shadow-md drop-shadow-md lg:w-[80%] p-2  mb-[7rem]  lg:ml-[17%] relative  lg:top-[1rem] fade-in-left ">
    
        <div
          className={`store_form_regestration ${
            catalougemoule || offermodule ? "hidden" : "block"
          }`}
        >
          <form onSubmit={hanldeSubmit}>
            {togglecatalouge ? (
              <>
                {" "}
                <div className="_catlouge|addoffer|form">
                  <Catalouge_Offer_Form
                    settogglecatalouge={settogglecatalouge}
                    formData = {formData}
                    handleCatalogueChange = {handleCatalogueChange}
                  />
                </div>
              </>
            ) : (
              <>
                {" "}
                <div className="store_|form">
                  <div className="flex md:flex-row  lg:flex-row flex-col   lg:gap-[4rem] mt-[3rem] mb-[2rem]">
                    {/* 1 */}
                    <div className="form-input flex flex-col items-center  px-4">
                      <h1 className="font-all font-semibold text-center ">
                        RESGISTER YOUR STORE
                      </h1>
                      {/* 1 name field  */}
                      <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[100%] lg:w-[541px]  lg:ml-[2.1rem] ">
                        <label
                          htmlFor="input"
                          className=" text text-sm px-2 text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2  bg- w-fit-content text-center"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          placeholder="Write here..."
                          name="name"
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                          value={formData.catalogue.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>{" "}
                      {/* 2 store type*/}
                      <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[100%] lg:w-[32rem]">
                        <label
                          htmlFor="input"
                          className="text text-sm text-black w-max rounded-md bg-white relative top-2.5 ml-2 px-2 w-fit-content"
                        >
                          Type:
                        </label>
                        <select
                          name="type"
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] lg:w-[541px] rounded-md focus:border-[1px]"
                          value={formData.type}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select an option</option>
                          <option value="option1">STORE</option>
                          <option value="option2">service</option>
                          <option value="option3">company</option>
                        </select>
                      </div>
                      {/* cataloge section  */}
                      <div className="flex flex-row   items-center justify-center my-4  gap-4">
                        <span
                          onClick={toggleform}
                          className="bg-[#c6c6c685] shadow-mdl lg:mx-auto p-[6px] w-[13rem] xs:w-[10rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#cb2229]  hover:text-white  text-center cursor-pointer"
                        >
                          Catalogue
                        </span>
                        <span className="bg-[#c6c6c685] shadow-mdl lg:mx-auto p-[6px] w-[13rem]  xs:w-[10rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#cb2229]  hover:text-white text-center cursor-pointer">
                          Add Offer
                        </span>
                      </div>
                      {/* 2  */}
                      <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[100%] lg:w-[541px]  lg:ml-[2.1rem] ">
                        <label
                          htmlFor="input"
                          className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                        >
                          Location
                        </label>
                        <input
                          type="text"
                          placeholder="Write here..."
                          name="location"
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                          value={formData.location}
                          onChange={handleInputChange}
                          required
                        />
                      </div>{" "}
                      {/*  */}
                      <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[100%] lg:w-[541px]  lg:ml-[2.1rem] ">
                        <label
                          htmlFor="input"
                          className=" text text-sm  text-black w-max  rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                        >
                          Timing :
                        </label>
                        <input
                          type="text"
                          placeholder="Write here..."
                          name="timing"
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                          value={formData.timing}
                          onChange={handleInputChange}
                          required
                        />
                      </div>{" "}
                      {/*  */}
                      {/* 5  */}
                      <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[100%] lg:w-[541px]  lg:ml-[2.1rem] ">
                        <label
                          htmlFor="input"
                          className=" text text-sm  text-black w-[7rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                        >
                          Phone Number
                        </label>
                        <input
                          type="number"
                          placeholder="Write here..."
                          name="PhoneNumber"
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                          value={formData.PhoneNumber}
                          onChange={handleInputChange}
                          required
                        />
                      </div>{" "}
                      {/*  */}
                      <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[100%] lg:w-[541px]  lg:ml-[2.1rem] ">
                        <label
                          htmlFor="input"
                          className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                        >
                          Email ID
                        </label>
                        <input
                          type="text"
                          placeholder="Write here..."
                          name="EmailID"
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                          value={formData.EmailID}
                          onChange={handleInputChange}
                          required
                        />
                      </div>{" "}
                      <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[100%] lg:w-[541px]  lg:ml-[2.1rem] ">
                        <label
                          htmlFor="input"
                          className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                        >
                          Years of Establishment
                        </label>
                        <input
                          type="text"
                          placeholder="Write here..."
                          name="yearsofEstablishment"
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                          value={formData.yearsofEstablishment}
                          onChange={handleInputChange}
                          required
                        />
                      </div>{" "}
                      <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[100%] lg:w-[541px]  lg:ml-[2.1rem] ">
                        <label
                          htmlFor="input"
                          className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                        >
                          Awards
                        </label>
                        <input
                          type="text"
                          placeholder="Write here..."
                          name="awards"
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                          value={formData.awards}
                          onChange={handleInputChange}
                          required
                        />
                      </div>{" "}
                      <div className="coolinput flex flex-col w-fit-content static max-w-240 xs:w-[100%] lg:w-[541px]  lg:ml-[2.1rem] ">
                        <label
                          htmlFor="input"
                          className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-2 bg- w-fit-content text-center"
                        >
                          Certificates
                        </label>
                        <input
                          type="text"
                          placeholder="Write here..."
                          name="Certificates"
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                          value={formData.Certificates}
                          onChange={handleInputChange}
                          required
                        />
                      </div>{" "}
                      {/*  */}
                    </div>

                    {/* 2 */}

                    <div className="inline-flex flex-col mt-[4rem] lg:gap-[2rem]">
                      {/*  */}
                      <div className="flex items-center justify-center w-full">
                        <label
                          style={{
                            display: selectedImage ? "none" : "block",
                          }}
                          htmlFor="dropzone-file"
                          className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              className="w-8 h-8 mb-4 text-gray-500 shark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 shark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload banner of store image
                              </span>{" "}
                              or drag and drop
                            </p>
                            <p className="text-xs text-gray-500 shark:text-gray-400">
                              SVG, PNG, JPG, or GIF (MAX. 800x400px)
                            </p>
                          </div>
                          <input
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                            onChange={handleImageChange}
                          />
                        </label>
                        {selectedImage && (
                          <div className="flex items-center  w-auto p-[10px] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600">
                            <img
                              className="w-full h-44 mx-auto "
                              src={selectedImage}
                              alt="Selected"
                            />
                          </div>
                        )}
                      </div>
                      {isImageSelected && (
                        <p className="text-red-500">
                          Please Upload Image to continue
                        </p>
                      )}

                      {/*  */}

                      {/* buttin action */}

                      <div className=" button-action xs:gap-2 flex flex-col items-center mt-[3.5rem]">
                        <button
                          type="submit"
                          className="  bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem] flex flex-row  justify-center items-center gap-2  transform hover:scale-105 duration-500 ease-in-out relative"
                        >
                          {loading && <Spinner />} Submit Store
                        </button>

                        <Link to="/">
                          <button className=" mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                            Go Back
                          </button>
                        </Link>
                      </div>
                    </div>
                    {/*  */}
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
        {/* {showModal ? (
          <>
            <PromotionPopup
              setShowModal={setShowModal}
              handleButtonClick={handleButtonClick}
              setnotificationmodal={setnotificationmodal}
            />
          </>
        ) : null} */}

        {notificationmodal ? (
          <>
            <NotificationModel setnotificationmodal={setnotificationmodal} />
          </>
        ) : null}

        {/* {catalougemoule ? (
          <>
            <Editcatalougefrom setCatalougeModule={setCatalougeModule} />
          </>
        ) : null}

        {offermodule ? (
          <>
            <AddofferForm setOfferModule={setOfferModule} />
          </>
        ) : null} */}
      </div>
    </>
  );
}
