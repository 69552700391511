import React, { useState, useEffect } from "react";

import Homeslider from "./HomePageMainComponent/Homeslider";
import Services from "./HomePageMainComponent/Services";
import MapStock from "./HomePageMainComponent/MapAndTrend/MapStock";
import Buttonsmain from "./HomePageMainComponent/TargerserviceButton/Buttonsmain";
import ProductHomeslider from "./HomePageMainComponent/ProductCardSlider/ProductHomeslider";
import Servivevideo from "./HomePageMainComponent/ServicesNear/Servivevideo";
import GifComponent from "../Auth/GifComponent";
import Mobile_SLider_card from "../../Widgets/Mobile_SLider_card";
import ProductForYou from "./ServicesByINO/FindProduct/Allmain/ProductForYou";
import ExploreService from "./ServicesByINO/ExploreService/ExploreAll/ExploreService";
function Homestock() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulating a delay to represent website loading
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Change the duration as needed
  }, []);
  return (
    <>
      <div className="p-4 xs:p-0 sm:ml-[-2rem]">
        {/* Main content components */}
        <div className=" p-4 xs:p-0 sm:ml-64 bg-layout  xs:h-screen">
          <div className=" ">
            <div className=" flex items-center justify-center h-48 mb-8 slide-in-elliptic-top-fwd">
              <Homeslider />
            </div>

            <div className="p-4 ">
              <div className="xs:hidden">
                <div className=" grid grid-cols-2 zoom-custom  mb-4 lg:mt-[18rem] lg:items-start lg:gap-[1rem]">
                  <div className=" flex items-center rounded   h-28  lg:h-[33rem] ">
                    <Services />
                  </div>

                  <div className=" flex items-center justify-center rounded h-28 lg:mt-[208px] ">
                    <MapStock />
                  </div>
                </div>
                <div className="services-section-button lg:mt-[4rem] xs:hidden">
                  <Buttonsmain />
                </div>
                <div
                  className=" flex items-center justify-center h-48 mt-5 rounded  lg:h-max xs:hidden"
                  style={{ border: "1px solid #D2D2D2" }}
                >
                  <div className="w-[140vh]">
                    {/* <ProductHomeslider /> */}
                    <ProductForYou />
                  </div>
                </div>
                <div
                  className="flex items-center justify-center mt-10 rounded  xs:hidden"
                  style={{ border: "1px solid #D2D2D2" }}
                >
                  <ExploreService />
                </div>
              </div>
              <Mobile_SLider_card />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Homestock;
