// list service

import { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import {
  getServiceListDa,
  listService,
} from "../../../../../api/userRegestration/CollectionForm";
import Spinner from "../../../../../component/Spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PromotionFormBody from "../../../../PromotionForm/PromotionFormBody";
import { TbSpeakerphone } from "react-icons/tb";
import PromotionPopup from "../../../../../Widgets/PromotionPopup";
import NotificationModel from "../../../../../Widgets/NotificationModel";
export default function ExploreInputMain() {
  const [categories, setCategories] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const accessToken = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [notificationmodal, setnotificationmodal] = useState(!true);
  //

  const [promotebutton, setPromoteButton] = useState(true);
  const [getserviceId, setgetserviceId] = useState();
  //

  const handleInputChanges = (event) => {
    setSearchInput(event.target.value);
  };

  //
  const handleButtonClick = () => {
    toast.success("you are now at promotion form!", {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setPromoteButton(!promotebutton);
  };
  //
  const [formData, setFormData] = useState({
    ServiceCateogries: "",
    ServiceName: "",
    ServiceRate: "",
    ServiceDetails: "",
    img: "",
    Relevantskills: "",
    // shareBusinessCard: false,
    // followGuidelines: false,
  });
  //

  const toggenotifcation = () => {
    setnotificationmodal(true);
  };
  const dimissnotification = () => {
    console.log("clicked");
    setnotificationmodal(false);

    setShowModal(true);
  };

  const handlesubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const service_List_Data = {
        serviceName: selectedCategory,
        
        details: formData.ServiceDetails,
        rate: formData.ServiceRate,
      
        categorie: selectedCategory,
        type: "BUYER",
      };

      const service_Data = await listService(service_List_Data);
      console.log("PRODUCT ID FROM THE PARENT COMPONENT ", service_Data.result);
      console.log(
        "request  FROM THE PARENT COMPONENT ",
        service_Data.result.saveRequest.serviceId
      );
      //
      let serviceId;

      setgetserviceId(service_Data.result.saveRequest.serviceId);
      console.log("id capsuled under state ");

      if (
        service_Data &&
        service_Data.result &&
        service_Data.result.saveRequest.serviceId
      ) {
        serviceId = service_Data.result.saveRequest.serviceId;

        setgetserviceId((serviceId) => {
          console.log("previous service Id ", serviceId);
          return serviceId;
        });

        console.log(
          "\x1b[32m%s\x1b[0m",
          "product id from scattered state",
          serviceId
        );
      } else {
        console.log(" ID not found in the response.");
      }

      console.log("service_list", service_Data);

      // toaster
      toast.success("service  hitted  successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setShowModal(true);
    } catch (error) {
      console.error("Error in :", error.message);
    } finally {
      setLoading(false);
    }
  };
  //

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      convertImageToBase64(file); // Convert and store the image as Base64
    } else {
      setSelectedImage(null);
      setImageBase64(null); // Clear the Base64 data if no image is selected
    }
  };

  const convertImageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result;
      setImageBase64(base64Data);
    };
    reader.readAsDataURL(file);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };
  //
  useEffect(() => {
    getServiceListDa(accessToken)
      .then((result) => {
        setCategories(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);
  //
  useEffect(() => {
    if (getserviceId) {
      console.log("Product ID from state:", getserviceId);
    }
  }, [getserviceId]);
  //
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  //
  return (
    <>
      {promotebutton ? (
        <>
          <div className="form">
            <form onSubmit={handlesubmit}>
              <div className="flex flex-row xs:flex-col lg:gap-[9rem] lg:justify-center mt-[3rem] mb-[2rem]">
                {/* 1 */}
                <div className="form-input flex flex-col  w-[47%] xs:w-[90%] xs:mx-auto">
                  <h1 className="font-all font-semibold text-center xs:hidden">
                    Find Services
                  </h1>
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ">
                    <label
                      htmlFor="select"
                      className="text text-sm text-black w-[8rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg-w-fit-content text-center"
                    >
                      Service Categories
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="category"
                      className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      value={selectedCategory} // Use the selectedCategory state here
                      onChange={(e) => {
                        handleInputChanges(e);
                        setSelectedCategory(e.target.value); // Update selected identification
                      }}
                      onFocus={toggleDropdown}
                      required
                    />
                  </div>
                  {isOpen &&
                        categories.some((category) =>
                          category.name
                            .toLowerCase()
                            .includes(selectedCategory.toLowerCase())
                        ) && (
                          <ul
                            className="absolute h-96 overflow-scroll z-10  xs:w-96 w-[19rem]  ml-12 mt-[4.3rem] bg-white border rounded-md shadow-md  slide-in-blurred-top list-disc"
                            style={{ scrollBehavior: "smooth" }}
                          >
                            <label
                              className="mx-auto  bg-white min-w-sm max-w-2xl flex flex-col md:flex-row items-center justify-center  py-2 px-2  gap-2 shadow-2xl focus-within:border-gray-300 sticky top-0"
                              htmlFor="search-bar"
                            ></label>

                            {categories
                              .filter((category) =>
                                category.name
                                  .toLowerCase()
                                  .includes(searchInput.toLowerCase())
                              )
                              .map((category) => (
                                <li
                                  key={category._id}
                                  onClick={() => {
                                    setIsOpen(!isOpen);
                                    setSelectedCategory(category.name); // Update the selected category
                                  }}
                                  className={`flex items-center p-2 cursor-pointer hover:bg-gray-100 `}
                                >
                                  {category.name}
                                </li>
                              ))}
                          </ul>
                        )}
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]   ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      Service Name
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="ServiceName"
                      className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      // aria-autocomplete="list"
                      value={formData.ServiceName}
                      onChange={handleInputChange}
                    />
                  </div>{" "}
                  {/*  */}
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]   ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      Service Rate
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="ServiceRate"
                      className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      // aria-autocomplete="list"
                      value={formData.ServiceRate}
                      onChange={handleInputChange}
                      required
                      onKeyPress={(event) => {
                        // Allow only digits (0-9) and backspace
                        const keyCode = event.keyCode || event.which;
                        const keyValue = String.fromCharCode(keyCode);
                        const regex = /[0-9\b]/;
                        if (!regex.test(keyValue)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>{" "}
                  {/*  */}
                  {/* 5  */}
                  {/* text area  */}
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ">
                    <label
                      htmlFor="input"
                      className="text text-sm text-black w-[7rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      Service Details
                    </label>
                    <textarea
                      placeholder="Write here..."
                      name="ServiceDetails"
                      rows="4"
                      className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px] "
                      value={formData.ServiceDetails}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  {/*  */}
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ">
                    <label
                      htmlFor="input"
                      className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      Relevant skills/Keywords
                    </label>
                    <textarea
                      placeholder="Write here..."
                      name="Relevantskills"
                      rows="7"
                      className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      value={formData.Relevantskills}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  {/*  */}
                </div>

                {/* 2 */}

                <div className="inline-flex flex-col mt-[4rem] xs:mt-4 lg:gap-[3rem]">
                  {/*  */}
                  {/* <div className="flex items-center justify-center w-full">
                    <label
                      style={{
                        display: selectedImage ? "none" : "block",
                      }}
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          className="w-8 h-8 mb-4 text-gray-500 shark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 shark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        <p className="text-xs text-gray-500 shark:text-gray-400">
                          SVG, PNG, JPG, or GIF (MAX. 800x400px)
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        onChange={handleImageChange}
                        required
                      />
                    </label>
                    {selectedImage && (
                      <div className="flex items-center  w-auto p-[10px] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600">
                        <img
                          className="w-full h-44 mx-auto "
                          src={selectedImage}
                          alt="Selected"
                        />
                      </div>
                    )}
                  </div> */}

                  {/*  */}
                  <div className="/ w-max xs:mx-auto xs:mt-2">
                    <div className=" flex items-center ">
                      <input
                        id="link-checkbox"
                        type="checkbox"
                        value=""
                        className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
                        required
                      />
                      <label
                        for="link-checkbox"
                        className="xs:text-[12px] ml-2 text-sm font-all font-semibold text-gray-900 shark:text-gray-300"
                      >
                        I agree to share my Bussiness card with Supplier .
                      </label>
                    </div>

                    <div className=" flex items-center mt-3">
                      <input
                        id="link-checkbox"
                        type="checkbox"
                        value=""
                        className="xs:text-[12px] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 shark:focus:ring-blue-600 shark:ring-offset-gray-800 focus:ring-2 shark:bg-gray-700 shark:border-gray-600"
                        required
                      />
                      <label
                        for="link-checkbox"
                        className="xs:text-[12px] ml-2 text-sm font-all font-semibold text-gray-900 shark:text-gray-300"
                        required
                      >
                        I agree to follow buyer posting guideline .
                      </label>
                    </div>
                  </div>

                  {/* buttin action */}

                  <div className=" button-action flex flex-col items-center mt-6 gap-4">
                    <button
                      type="submit"
                      className="  bg-[#CB2229] flex flex-row justify-center items-center gap-2 text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
                      disabled={loading}
                    >
                      {loading && <Spinner />} Submit
                    </button>
                    {/* <Link to="/create_module_promotion" > */}
                    <button
                      disabled
                      className=" mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  "
                    >
                      Create a Campaign
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
                {/*  */}
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="conditional action b">
            <PromotionFormBody data={getserviceId} />
          </div>
        </>
      )}

      {showModal ? (
        <>
          <PromotionPopup
            setShowModal={setShowModal}
            handleButtonClick={handleButtonClick}
            setnotificationmodal={setnotificationmodal}
          />
        </>
      ) : null}

      {notificationmodal ? (
        <>
          <NotificationModel setnotificationmodal={setnotificationmodal} />
        </>
      ) : null}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
