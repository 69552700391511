import React from "react";

import logo from "../assets/logo/Logo-Trans.png";
function Loader() {
  return (
    <>
      <div className="h-screen md:h-[80vh] lg:h-[80vh] 2xl:h-[80vh]">
        <div className="flex flex-col items-center justify-center mx-auto">
          <div className="p-[38px] shadow-md  rounded-md ---img--action-imate mt-[16%] slit-in-diagol-2 ">
            <img className="h-[4rem] slit-in-diagona-2 " src={logo} alt="" />
          </div>
        </div>

        <div className="flex flex-row items-center gap-3 justify-center mt-[8rem] relative left-[19px]">
          <p className="font-all font-bold ">loading content....</p>
          <div className=" animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full" role="status" aria-label="loading">
  <span className=" sr-only">Loading...</span>
</div>
        </div>
      </div>
    </>
  );
}

export default Loader;
