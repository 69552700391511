import React from 'react'

function Validation_Toaster() {
  return (
   <>
      <div
                  role="alert"
                  className="alert xs:flex  shadow-lg absolute top-2 w-[50%] xs:w-full left-[50%] right-[50%] fade-in-top mr-6"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="stroke-info shrink-0 w-6 h-6 text-red-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <div>
                    <h3 className="font-bold">validation error</h3>
                    <div className="text-md font-all font-semibold">
                      Enter the phone number to continue
                    </div>
                  </div>
                </div>
   </>
  )
}

export default Validation_Toaster