import React, { useEffect, useState } from "react";

import { fetch_referal_code } from "../../api/userRegestration/CollectionForm";
import { useWallet } from "../Wallet/Context/WalletContext";

function ReferQRcode() {
  const profiledata = useWallet();
  const [jobdetails, setpjobdetails] = useState();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch_referal_code()
      .then((response) => {
        console.log("API Response:", response);
        setpjobdetails(response.result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleCopyCode = () => {
    const textField = document.createElement("textarea");
    textField.innerText = jobdetails.referralCode;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    document.body.removeChild(textField);
    setIsCopied(true);
  };
  return (
    <>
      <div className="fade-in-left flex flex-col items-center border-[1px] border-[#D2D2D2]  rounded-md lg:p-[28px] lg:mt-[4rem] lg:mb-[7rem]">
        <h1 className=" font-all font-extrabold text-[23px] text-color lg:relative mb-4">
          Refer to a Friend !
        </h1>
        <div className="text-top">
          <h6 className="font-all text-center  text-[#000000]">
            Invite your friend to INO
          </h6>
          <h6 className="font-all  text-[#000000] text-center">
            Earn Rewards and Points 10 for each referal sent !
          </h6>
          {/* <div className="text-referal">
            <h3 className="text-[#000000] text-center font-all  mt-1">
              Here is you Referal link
            </h3>
            <h4 className="text-[#4460A0] fot-all relative text-center mt-1">
              https://www.dummylinkINO.com
            </h4>
          </div> */}
          <div className="qr-code">
            <h3 className="text-[#000000] font-all text-center mt-1">
              {" "}
              Here is your Referalcode{" "}
            </h3>
            <div className="icong-image flex justify-center ">
              {/* <img
                src="https://i.pinimg.com/564x/cd/c7/3b/cdc73bef50d55c07511f10fbf67fa47c.jpg"
                className="lg:h-[8pc]  rounded-md"
                alt=""
              /> */}

              <div className="shadow-lg font-all font-semibold p-[20px] drop-shadow border rounded-md my-6 text center">
                {/* {jobdetails ? ( */}
                <div>
                  <p
                    className="tracking-[3px]
"
                  >
                    {profiledata && (
                      <>
                        {profiledata.referralCode ? (
                          <>{profiledata.referralCode}</>
                        ) : (
                          <>become a reseller to continue</>
                        )}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="button-action flex flex-col gap-2 items-center ">
              <button
                onClick={handleCopyCode}
                className="bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem] transform hover:scale-105 duration-500 ease-in-out relative"
              >
                {isCopied ? "Code Copied!" : "Copy Code"}
              </button>
              <button className=" mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                Share code
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReferQRcode;
